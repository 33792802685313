import {isDEVMODE, domStorage, viewStorage} from '../_globals'

export default class Form {

  constructor(formSelector) {

    this.DOM = {
      form: formSelector
    }

    this.DOM.inputs = this.DOM.form.querySelectorAll('input, textarea')
    this.DOM.fields = this.DOM.form.querySelectorAll('input, select, textarea')
    this.DOM.resultText = this.DOM.form.querySelector('.Form__output p')
    this.DOM.submitBtn = this.DOM.form.querySelector('button[type=submit]')

    this.formURL = this.DOM.form.dataset.ajaxurl

    this.init()
  }

  init() {

    if (isDEVMODE) console.log('Init Form')

    this.addEvents()

  }

  addEvents() {

    const {form, inputs} = this.DOM

    this.onSubmit = this.onSubmit.bind(this)
    form.addEventListener('submit', this.onSubmit)

    this.onFocus = this.onFocus.bind(this)
    inputs.forEach(input => input.addEventListener('focusin', this.onFocus))
    inputs.forEach(input => input.addEventListener('focusout', this.onFocus))
  }

  onFocus(e) {
    const {type, currentTarget} = e
    if (type === 'focusin') currentTarget.parentNode.classList.add('is--focused')
    else currentTarget.parentNode.classList.remove('is--focused')

    if (type === 'focusout' && currentTarget.value !== '') currentTarget.parentNode.classList.add('is--filled')
    else currentTarget.parentNode.classList.remove('is--filled')
  }

  onSubmit(e) {
    e.preventDefault()

    const {body} = domStorage
    const {viewScroll} = viewStorage
    const {fields, submitBtn} = this.DOM

    body.classList.add('is--loading')

    submitBtn.disabled = true

    /* Remove previous errors */
    this.resetErrors()

    /* Add values to formData */
    const formData = this.getData()

    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.execute('6LcnHuAnAAAAAGutIIwOXlGk2u-XaGWMbsLw3ReM', {action: 'submit'}).then((token) => {

        const xhr = new XMLHttpRequest()
        xhr.open('POST', this.formURL, true)
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {

            switch (xhr.status) {
              case 200:
                this.onSuccess(xhr.responseText)
                break
              case 422:
                this.onInvalid(xhr.responseText)
                break
              default:
                this.onServerError()
            }

            submitBtn.disabled = false

            fields.forEach(field => {
              field.disabled = false
            })

            body.classList.remove('is--loading')
            if (viewScroll) viewScroll.update()
          }
        }

        xhr.send(formData)
      });
    } else {
      const {resultText} = this.DOM
      resultText.innerHTML = 'A la suite d’un problème technique le formulaire ne peut être envoyé. Nous vous invitons à nous envoyer directement un email à etilly-deparis@public-address.fr.'
      resultText.classList.add('is--error')
    }
  }

  resetErrors() {
    const {form, resultText} = this.DOM

    resultText.innerHTML = ''
    resultText.classList.remove('is--error')

    const errors = form.querySelectorAll('span.is--error')
    errors.forEach((err) => {
      err.parentNode.classList.remove('is--error')
      err.parentNode.removeChild(err)
    })

  }

  getData() {
    const {fields} = this.DOM

    const formData = new FormData()
    fields.forEach(field => {
      field.disabled = true
      if (field.type === 'radio' || field.type === 'checkbox') {
        if (field.checked) formData.append(field.name, field.value)
      } else if (field.type === 'file') {
        if (field.multiple) {
          const ins = field.files.length;
          for (let i = 0; i < ins; i++) {
            formData.append(field.name[i], field.files[i]);
          }
        } else {
          formData.append(field.name, field.files[0]);
        }
      } else {
        formData.append(field.name, field.value)
      }
    })

    return formData
  }

  onSuccess(text) {
    const {form, resultText} = this.DOM

    resultText.innerHTML = text
    form.reset()
  }

  onInvalid(text) {
    const {form} = this.DOM
    const json = JSON.parse(text)

    for (const fieldName of Object.keys(json)) {
      const field = form.querySelector(`[name=${fieldName}]`)
      if (field) {
        const err = document.createElement('span')
        err.innerHTML = json[fieldName].join('. ')
        err.classList.add('is--error')
        field.parentNode.appendChild(err)
        field.parentNode.classList.add('is--error')
      }
    }
  }

  onServerError() {
    const {resultText} = this.DOM

    resultText.innerHTML = 'Une erreur est survenue. Merci de réessayer ulterieurement'
    resultText.classList.add('is--error')
  }

  destroy() {
    const {form, inputs} = this.DOM

    form.removeEventListener('submit', this.onSubmit)
    inputs.forEach(input => input.removeEventListener('focusin', this.onFocus))
    inputs.forEach(input => input.removeEventListener('focusout', this.onFocus))
  }
}
