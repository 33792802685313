import Sniffer from 'snifferjs'
import { isDEVMODE, globalStorage, domStorage } from '../_globals'

export const onSniff = () => {

  const Sniff = new Sniffer(navigator.userAgent)
  const htmlNode = document.getElementsByTagName('html')

  const classNameArr = htmlNode[0].className ? [htmlNode[0].className] : []

  Sniff.browser.name && classNameArr.push(Sniff.browser.name)
  Sniff.browser.engine && classNameArr.push(Sniff.browser.engine)
  Sniff.os.name && classNameArr.push(Sniff.os.name)

  for (const prop in Sniff.features) {
    if (Sniff.features[prop]) classNameArr.push(prop)
  }

  // Not Supported Browser
  if (Sniff.browser.name === 'chrome' && Sniff.browser.majorVersion <= 87 ||
    Sniff.browser.name === 'ie' && Sniff.browser.majorVersion <= 11 ||
    Sniff.browser.name === 'edge' && Sniff.browser.majorVersion <= 87 ||
    Sniff.browser.name === 'firefox' && Sniff.browser.majorVersion <= 78 ||
    Sniff.browser.name === 'safari' && Sniff.browser.majorVersion < 10 ||
    Sniff.browser.name === 'opera' && Sniff.browser.majorVersion <= 12) {
    domStorage.outdated.classList.add('show')
  }

  // touch Class
  if ('ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
    classNameArr.push('touch')
    globalStorage.isTouch = true
  }

  // Add Classes
  htmlNode[0].className = classNameArr.join(' ')

  // Update Globals
  globalStorage.browser.isMobile = Sniff.features.mobile
  globalStorage.browser.isChrome = Sniff.browser.name === 'chrome'
  globalStorage.browser.isSafari = Sniff.browser.name === 'safari'
  globalStorage.browser.isFirefox = Sniff.browser.name === 'firefox'
  globalStorage.browser.isOpera = Sniff.browser.name === 'opera'
  globalStorage.browser.isEdge = Sniff.browser.name === 'edge'
  globalStorage.browser.isIE = Sniff.browser.name === 'ie'
  globalStorage.browser.browserVersion = Sniff.browser.majorVersion

  // if (isDEVMODE) console.log('Sniffer :', Sniff)

}
