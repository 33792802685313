import gsap from 'gsap'
import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper'
Swiper.use([Navigation, Pagination, Scrollbar])

export default class MobileSwiper {
  constructor(container) {
    this.DOM = { container }

    this.mobileSwiper
    this.ww = window.innerWidth
    
    this.mm = gsap.matchMedia()
    this.breakpoint = 768

    this.mm.add({
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }, (context) => {
      const { isDesktop, isMobile } = context.conditions

      if (isDesktop) {
        if (this.mobileSwiper !== undefined) this.mobileSwiper.destroy(true, true)
        return
      } else {
        return this.enableSwiper()
      }
    })    
  }

  enableSwiper() {
    const { container } = this.DOM

    this.swiper = new Swiper(container, {
      speed: 350,
      spaceBetween: 20,
      longSwipesRatio: 0.1,
      slidesPerView: 'auto',
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: container.querySelector('.next'),
        prevEl: container.querySelector('.prev')
      }
    })
  }

  destroy() {
    if (this.swiper) this.swiper.destroy(true, true)
  }
}