import { Renderer } from '@unseenco/taxi';
import { domStorage, isDEVMODE, viewStorage } from '../_globals'
import Swiper, { Navigation, Grid } from 'swiper'
Swiper.use([ Navigation, Grid ])

export default class PageAccompaniment extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      timeline: viewStorage.current.querySelector('.Timeline'),
      partners: viewStorage.current.querySelector('.Partners'),
      partnersSwiper: viewStorage.current.querySelector('.Partners .swiper')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { partnersSwiper } = this.DOM

    if (partnersSwiper) this.setSwiper()

  }

  setSwiper() {
    const { partners, partnersSwiper } = this.DOM

    var swiper = new Swiper(".mySwiper", {
      spaceBetween: 20,
      slidesPerView: 3,
      breakpoints: {
        768: {
          slidesPerView: 4,
          grid: {
            rows: 3,
          },
        }
      },
      navigation: {
        prevEl: partners.querySelector('.prev'),
        nextEl: partners.querySelector('.next'),
      }
    })
  }

  onLeaveCompleted() {
    const { swiper } = this

    if (swiper) swiper.destroy()
  }
}
