import { Renderer } from '@unseenco/taxi';
import { domStorage, isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'

export default class SingleProduct extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      gallerySwiper: viewStorage.current.querySelector('.PageHeaderProduct .swiper'),
      relatedProductsSwiper: viewStorage.current.querySelector('.RelatedProducts .swiper')
    }

    this.init()

  }

  init() {
    const { 
      gallerySwiper = null,
      relatedProductsSwiper = null
    } = this.DOM

    if (gallerySwiper) this.gallerySwiperModule = new WknSwiper(gallerySwiper, { spaceBetween: 0 })
    if (relatedProductsSwiper) this.relatedProductsSwiperModule = new MobileSwiper(relatedProductsSwiper)

  }

  onLeaveCompleted() {
    this.gallerySwiperModule && this.gallerySwiperModule.destroy()
    this.relatedProductsSwiperModule && this.relatedProductsSwiperModule.destroy()
  }
}
