import MobileSwiper from './mobile-swiper'

export default class RelatedRealisations {
  constructor(container) {
    this.DOM = { container }

    if (!this.DOM.container) return

    this.DOM.swiper = this.DOM.container.querySelector('.swiper')

    this.setMobileSwiper()
  }

  setMobileSwiper() {
    const { swiper } = this.DOM

    this.mobileSwiper = new MobileSwiper(swiper)
  }

  destroy() {
    const { mobileSwiper } = this

    if(mobileSwiper) mobileSwiper.destroy()
  }
}