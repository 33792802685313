import gsap from 'gsap'

export const agenciesIntro = container => {
  const agencies = container.querySelectorAll('.Agency')

  if (!agencies.length) return

  const agenciesLines = container.querySelectorAll('.Agency hr')
  const agenciesTitles = container.querySelectorAll('.Agency .ttl span')
  const agenciesAddresses = container.querySelectorAll('.Agency .address')
  const agenciesTels = container.querySelectorAll('.Agency .tel')
  const agenciesIconContainers = container.querySelectorAll('.Agency .Icon__container')

  gsap.set(agenciesLines, { scaleX: 0 })
  gsap.set(agenciesIconContainers, { scale: 0 })
  gsap.set(agenciesTitles, { yPercent: 100 })
  gsap.set([agenciesAddresses, agenciesTels], { opacity: 0, y: 15 })

  const tl = gsap
    .timeline({
      onComplete: () => {
        gsap.set(agenciesLines, { clearProps: 'all' })
        gsap.set(agenciesIconContainers, { clearProps: 'all' })
        gsap.set(agenciesTitles, { clearProps: 'all' })
        gsap.set(agenciesAddresses, { clearProps: 'all' })
        gsap.set(agenciesTels, { clearProps: 'all' })
      }
    })
    .fromTo(agenciesLines, { scaleX: 0 }, { scaleX: 1, transformOrigin: '0% 0%', ease: 'expo.inOut', stagger: 0.1, overwrite: true }, 0)
    .fromTo(agenciesIconContainers, { scale: 0 }, { scale: 1, ease: 'expo.inOut', duration: 0.8, stagger: 0.1, overwrite: true }, 0.1)
    .fromTo(agenciesTitles, { yPercent: 100 }, { yPercent: 0, ease: 'power3.out', stagger: 0.1, overwrite: true }, 0.3)
    .fromTo(agenciesAddresses, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.5, stagger: 0.1, ease: 'power2.inOut', overwrite: true }, 0.2)
    .fromTo(agenciesTels, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.5, stagger: 0.1, ease: 'power2.inOut', overwrite: true }, 0.25)

  return tl
}