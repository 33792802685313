import { Renderer } from '@unseenco/taxi';
import { domStorage, isDEVMODE, viewStorage } from '../_globals'
import Form from '../modules/form'

export default class PageContact extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      contactForm: viewStorage.current.querySelector('.Form')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { contactForm } = this.DOM

    if (contactForm) this.form = new Form(contactForm);

    let select = contactForm.querySelector('#contact_subject');

    select.addEventListener('change', (e) => {

      let selectedOption = contactForm.querySelector('[value=\'' + e.target.value + '\']');
      if (selectedOption) {
        this.displaySpecificFields(selectedOption.dataset.slug);
      }

      // Adapt the styles if Devis is selected
      // (e.target.value === '17' || e.target.value === '22') ? 
      //   contactForm.classList.add('--show-estimations') : 
      //   contactForm.classList.remove('--show-estimations')
    });
    let selectedOption = contactForm.querySelector('[value=\'' + select.value + '\']');
    if (selectedOption) {
      this.displaySpecificFields(selectedOption.dataset.slug);
    }
  }

  displaySpecificFields(slug) {
    const { contactForm } = this.DOM;
    let options = contactForm.querySelectorAll('.dependsOnSlugs')

    if (typeof slug == 'undefined') slug = ''

    options.forEach((item) => {
      if (item.classList.contains('dependsOnSlug_' + slug)) {
        if (item.tagName == 'INPUT')
          item.required = true
          item.style.display = 'flex'
      } else {
        item.style.display = 'none';
        if (item.tagName == 'INPUT')
          item.required = false
      }
    });
  }

  onLeaveCompleted() {
    const { form } = this

    if (form) form.destroy()
  }
}
