import { Renderer } from '@unseenco/taxi';
import { domStorage, isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import WknSwiper from '../modules/wkn-swiper'
import Swiper, { Navigation, EffectCards, Autoplay } from 'swiper'
Swiper.use([Navigation, EffectCards, Autoplay])

export default class SingleRealisation extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderRealisation'),
      pageHeaderVisual: viewStorage.current.querySelector('.PageHeaderRealisation .visual'),
      pageHeaderWrapper: viewStorage.current.querySelector('.PageHeaderRealisation .wrapper'),
      pageHeaderGallery: viewStorage.current.querySelector('.PageHeaderRealisation .gallery'),
      sectionAccompaniment: viewStorage.current.querySelector('.RealisationAccompaniment')
    }

    if (this.DOM.sectionAccompaniment) this.DOM.sectionAccompanimentSwiper = this.DOM.sectionAccompaniment.querySelector('.swiper')

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { pageHeaderWrapper, pageHeaderGallery, sectionAccompanimentSwiper } = this.DOM

    if (pageHeaderWrapper) this.setPageHeaderParallax()
    if (pageHeaderGallery) this.setPageHeaderGallery()
    if (sectionAccompanimentSwiper) this.setAccompanimentSwiper()

  }

  setPageHeaderParallax() {
    const { pageHeader, pageHeaderVisual, pageHeaderWrapper } = this.DOM

    const mm = gsap.matchMedia()
    const breakpoint = 768

    mm.add({
      isDesktop: `(min-width: ${breakpoint}px)`,
      isMobile: `(min-width: ${breakpoint - 1}px)`
    }, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        this.pageHeaderParallaxTl = gsap
          .timeline({
            scrollTrigger: {
              trigger: pageHeader,
              start: 'top top',
              end: 'bottom top',
              scrub: true,
              // markers: true
            }
          })
          .fromTo(pageHeaderVisual, { scale: 1.1 }, { scale: 1 })
          .to(pageHeaderWrapper, { yPercent: 10 })
      }
    })
  }

  setPageHeaderGallery() {
    const { pageHeaderGallery } = this.DOM

    this.gallery = new Swiper(pageHeaderGallery, { 
      slidesPerView: 'auto', 
      spaceBetween: 0,
      loop: true,
      autoplay: { 
        delay: 2500, 
        disableOnInteraction: false 
      },
      navigation: {
        prevEl: pageHeaderGallery.querySelector('.prev'),
        nextEl: pageHeaderGallery.querySelector('.next')
      }
    })
  }

  setAccompanimentSwiper() {
    const { sectionAccompaniment, sectionAccompanimentSwiper } = this.DOM

    this.accompanimentSwiper = new Swiper(sectionAccompanimentSwiper, {
      effect: 'cards',
      grabCursor: true,
      navigation: {
        prevEl: sectionAccompaniment.querySelector('.prev'),
        nextEl: sectionAccompaniment.querySelector('.next')
      }
    })
  }

  onLeaveCompleted() {
    const { gallery, accompanimentSwiper } = this

    if (gallery) gallery.destroy()
    if (accompanimentSwiper) accompanimentSwiper.destroy()

  }
}
