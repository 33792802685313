import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)
import PageHeaderHome from '../animations/page-header-home'
import DiscoverSections from '../animations/discover-sections'
import RelatedRealisations from '../modules/related-realisations'
import Testimonial from '../modules/testimonial'
import WknSwiper from '../modules/wkn-swiper'

export default class PageHome extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      header: viewStorage.current.querySelector('.PageHeaderHome'),
      scrollButton: viewStorage.current.querySelector('.ScrollIndicator'),
      discover: viewStorage.current.querySelector('.Discover'),
      realisations: viewStorage.current.querySelector('.RelatedRealisations'),
      testimonialSwiper: viewStorage.current.querySelector('.Testimonial .swiper'),
      swiper: viewStorage.current.querySelector('.HomeProducts .swiper')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log(`Init Page : ${this.content.dataset.taxiView}`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { header, scrollButton, discover, realisations, testimonialSwiper, swiper } = this.DOM

    if (header) this.pageHeader = new PageHeaderHome(header)
    if (scrollButton) scrollButton.addEventListener('click', this.scrollTo.bind(this))
    if (discover) this.discoverSections = new DiscoverSections(discover)
    if (realisations) this.realisations = new RelatedRealisations(realisations)
    if (testimonialSwiper) this.testimonial = new Testimonial(testimonialSwiper)
    if (swiper) this.swiperModule = new WknSwiper(swiper, { 
      spaceBetween: 15, 
      loop: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 2,
        },
        790: {
          slidesPerView: 4,
        }
      }})
  }

  scrollTo(e) {
    e.preventDefault()

    const { currentTarget } = e
    const nextSection = currentTarget.parentNode.nextElementSibling

    if (!nextSection) return

    gsap.to(window, { scrollTo: nextSection, ease: 'circ.inOut', duration: 1 })
  }

  onLeaveCompleted() {
    const { scrollButton } = this.DOM
    const { pageHeader, discoverSections, testimonial } = this

    if (scrollButton) scrollButton.removeEventListener('click', this.scrollTo.bind(this))

    if (pageHeader) pageHeader.destroy()
    if (discoverSections) discoverSections.destroy()
    if (testimonial) testimonial.destroy()
  }
}
