import gsap from 'gsap'

export default class LoadMore {
  constructor(container, cards, limit) {
    this.DOM = { container, cards }
    this.limit = limit

    if (!this.DOM.container && !this.cards.length && !this.limit) return

    this.DOM.button = this.DOM.container.querySelector('#LoadMore')

    // Base 
    this.base = 0
    // Add = Number of cards generated with events
    this.add = 6

    this.setLoadMore()
    this.DOM.button.addEventListener('click', this.loadMore.bind(this))
  }

  setLoadMore() {
    const { cards, button } = this.DOM
    const { base, limit } = this

    // The BASE = knowing where we need to slice the new array
    // in the event below ! At first, the base = the limit
    // (Ex: slice(0, 12) where 12 is the limit)
    this.base = limit

    if (cards.length <= this.base) return gsap.set(button, { display: 'none' })

    // We hide all the cards 
    gsap.set(cards, { display: 'none' })

    // We show only the first cards with the limit 
    gsap.set([...cards].slice(base, limit), { display: 'flex' })
  }

  loadMore(e) {
    e.preventDefault()

    const { cards, button } = this.DOM

    // Slicing the cards array (for each event)
    gsap.set([...cards].slice(this.base, this.base+this.add), { display: 'flex' })

    // Updating the base (for slicing for each event)
    this.base += this.add

    // Removing the "Load more" button
    if (this.base >= cards.length) gsap.set(button, { display: 'none' })
  }

  destroy() {
    const { button } = this.DOM

    button.removeEventListener('click', this.loadMore.bind(this))
  }
}
