import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import LoadMore from '../modules/load-more'
import WknSwiper from '../modules/wkn-swiper'

export default class ArchiveProductsType extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      searchProductForm: viewStorage.current.querySelector('form'),
      searchedProducts: document.getElementById('SearchedProducts'),
      productPagination: document.getElementById('productPagination'),
      typesChildrenInput: viewStorage.current.querySelectorAll('.types_children_input'),
      totalProducts: document.querySelector('#totalProducts'),
      posts_per_page: document.querySelector('#posts_per_page'),
      // highlightProductsSwiper: viewStorage.current.querySelector('.HighlightProducts .swiper')
    }
    this.init()
  }

  init() {
    const { searchProductForm, highlightProductsSwiper = null } = this.DOM

    let json_data = document.getElementById('json_data');
    if (json_data) {
      if (json_data.innerHTML) {
        json_data = JSON.parse(json_data.innerHTML);
        this.urlajax = json_data.urls.ajax.search_product;
      }
    }

    const parent_term = document.getElementById('parent_term');

    this.parent_term = parent_term.value;
    this.types_children = [];
    this.page = 1;
    this.search = '';
    this.posts_per_page = posts_per_page.value;

    if (searchProductForm) this.initSearchForm()
    // if (highlightProductsSwiper) this.highlightProductsSwiper = new WknSwiper(highlightProductsSwiper)
  }

  initChildrenTermsListeners() {
    const { typesChildrenInput } = this.DOM;
    typesChildrenInput.forEach((button, index) => {
      const m = button.addEventListener('click', (e) => {
          this.types_children = [];
          typesChildrenInput.forEach((checkbox, index) => {
            if (checkbox.checked) {
              this.types_children.push(checkbox.value);
            }
          });


          this.runSearch();
      });
    });
  }
  initPaginationListeners() {
    const { productPagination } = this.DOM;
    let buttons = productPagination.querySelectorAll('a');
    buttons.forEach((button, index) => {
      const m = button.addEventListener('click', (e) => {
        e.preventDefault();
        if (1) {
          this.page = parseInt(e.target.dataset.page);
          this.runSearch();
        }
      });
    });
  }
  initSearchForm() {
    const { searchProductForm, posts_per_page } = this.DOM
    // Script here
    let search_product = document.getElementById('json_data');
    search_product.addEventListener('keyup', (e) => {
      this.newResearch(e);
    });
    posts_per_page.addEventListener('change', (e) => {
      this.posts_per_page = e.target.value;
      this.runSearch();
    });
    searchProductForm.addEventListener('submit', (e) => {
      this.newResearch(e);
    });
    this.runSearch();
    this.initChildrenTermsListeners();
  }

  newResearch(e) {
    e && e.preventDefault();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.search = e.target.value;
      this.page = 1;
      this.runSearch();
    }, 500);
  }

  onSuccess(responseText) {
    const { searchedProducts, productPagination, totalProducts } = this.DOM
    const json = JSON.parse(responseText);
    searchedProducts.innerHTML = json.productGrid_html;
    productPagination.innerHTML = json.productPagination_html;
    totalProducts.innerHTML = json.totalProducts;
    this.initPaginationListeners();
  }

  runSearch() {
    const formData = new FormData();
    formData.append('page', this.page);
    formData.append('search', this.search);
    formData.append('posts_per_page', this.posts_per_page);

    formData.append('parent_term', this.parent_term);
    formData.append('types_children', this.types_children);

    const xhr = new XMLHttpRequest()
    xhr.open('POST', this.urlajax, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText)
            break
          case 422:
            break
          default:
            this.onServerError()
        }
      }
    }
    xhr.send(formData);
  }

  onLeaveCompleted() {
    // this.highlightProductsSwiper && this.highlightProductsSwiper.destroy()
  }
}
