import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class DiscoverSections {
  constructor(container) {
    this.DOM = { container }
    this.DOM.sections = this.DOM.container.querySelectorAll('.DiscoverSection')
    this.DOM.visuals = this.DOM.container.querySelectorAll('.visuals .visual__container')
    this.DOM.indexNumbers = this.DOM.container.querySelectorAll('.index .index-active span')

    if (!this.DOM.sections.length) return

    // Tweens array
    this.tweens = []
    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768


    this.setScrollAnimation()
  }

  setScrollAnimation() {
    const { container, sections, visuals, indexNumbers } = this.DOM

    this.mm.add({
       isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }, context => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        sections.forEach((section, index) => {
          const randomIn = gsap.utils.random(-10, 10, 0.5)
          const randomOut = gsap.utils.random(-10, 10, 0.5)

          gsap.set(indexNumbers, { opacity: index => index > 0 && 0 })

          const tl = gsap
            .timeline({
              defaults: { ease: 'power3.out', duration: 0.5 },
              scrollTrigger: {
                trigger: section,
                start: 'top 70%',
                end: 'bottom top',
                toggleActions: 'play pause resume reverse',
                fastScrollEnd: true,
                preventOverlaps: true
              }
            })
            .fromTo(visuals[index], { y: `+=${container.offsetHeight}`, rotate: randomIn, opacity: 0 }, {  y: 0, rotate: randomOut, opacity: 1 }, 0)
            if (indexNumbers[index - 1]) tl.to(indexNumbers[index - 1], { opacity: 0, duration: 0, ease: 'none' }, 0)
            tl.to(indexNumbers[index], { opacity: 1, duration: 0, ease: 'none' }, 0)

          this.tweens.push(tl)
        })
      }
    })
  }

  destroy() { 
    const { tweens } = this

    if (tweens.length) tweens.forEach(tween => tween.kill())
  }
}
